export default function Header() {
  return (
    <header className="p-4 py-11 flex flex-col gap-2 bg-primary md:gap-8">
      <h1 className="text-center text-white font-bold text-3xl md:text-5xl max-w-[50ch] m-auto">
        VOCÊ ACABOU DE GANHAR{" "}
        <mark className="text-secondary bg-[#00FF00]"> 30% DE DESCONTO! </mark>{" "}
        +
      </h1>

      <h2 className="text-center text-white font-bold text-3xl md:text-5xl max-w-[50ch] m-auto">
        Acompanhamento com{" "}
        <mark className="text-secondary bg-[#00FF00]">
          {" "}
          NUTRICIONISTA PARTICULAR!
        </mark>
      </h2>
    </header>
  );
}
