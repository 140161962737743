export default function Footer() {
  return (
    <footer className="p-4 bg-primary">
      <p className="text-center font-medium text-white text-base">
        Copyright © 2024 | Todos os direitos reservados. Aplicativo Oficial do
        truque da cerveja.
      </p>
    </footer>
  );
}
