export default function Hero() {
  return (
    <section className="p-4 py-11">
      <article className="flex flex-col gap-8 items-center text-center w-full max-w-4xl m-auto text-lg md:text-2xl text-secondary">
        <article className="flex flex-col gap-7 items-center w-full">
          <h1 className="text-secondary font-bold text-3xl md:text-5xl">
            E ainda vai Ganhar{" "}
            <mark className="text-primary bg-transparent font-bold">
              {" "}
              2 Presentes Surpresas!!!
            </mark>
          </h1>

          <a
            href="https://checkout.payt.com.br/22ced79030992233449d6c1e0cea47df"
            className="button pulse-animation regular-shadow"
          >
            QUERO O APP DA CERVEJA COM 30% DE DESCONTO
          </a>
        </article>

        <article className="flex flex-col gap-4">
          <h2 className="font-bold text-2xl md:text-4xl">
            Elimine essa barriga Agora!!!
          </h2>

          <h3 className="font-bold">Se você deseja…</h3>

          <p>
            …Eliminar aquela gordurinha chata que te incomoda há anos na barriga
            e pochete…
          </p>

          <p>
            ...Se sentir bem consigo mesmo quando você parar na frente do
            espelho…
          </p>

          <p>
            ...Não ser mais motivo de piada entre familiares e amigos por estar
            acima do peso…
          </p>

          <p>E voltar a ser aquele homem confiante e atraente…</p>

          <h4 className="font-bold">Então…</h4>

          <h5 className="font-bold">
            O{" "}
            <mark className="text-primary bg-transparent">APP DA CERVEJA</mark>{" "}
            é para você!!!
          </h5>

          <p>
            A única solução capaz de atacar a causa raiz do seu excesso de peso…
          </p>

          <p>
            ...e fazer você queimar até 10kg de gordura em{" "}
            <mark className="font-bold bg-transparent underline text-secondary">
              apenas 21 dias…
            </mark>
          </p>

          <p>
            Sem que você precise recorrer a{" "}
            <mark className="font-bold bg-transparent underline text-secondary">
              dietas restritivas, exercícios, receitas mentirosas ou muito menos
              suplementos caríssimos…
            </mark>{" "}
          </p>
        </article>

        <article className="flex flex-col gap-4 w-full items-center">
          <h2 className="font-bold text-primary text-2xl md:text-4xl">
            A DECISÃO ESTÁ NAS SUAS MÃOS!
          </h2>

          <p>Decisão que será responsável pela sua transformação corporal!!!</p>

          <p>
            Antes por{" "}
            <mark className="bg-transparent line-through text-primary">
              R$97,00
            </mark>{" "}
            ,{" "}
            <mark className="font-bold bg-transparent text-secondary">
              {" "}
              agora por apenas{" "}
              <mark className="bg-green-600 text-white font-bold">
                R$67,00
              </mark>{" "}
            </mark>
          </p>

          <a
            href="https://checkout.payt.com.br/22ced79030992233449d6c1e0cea47df"
            className="button pulse-animation regular-shadow mt-4"
          >
            QUERO O APP DA CERVEJA COM 30% DE DESCONTO
          </a>
        </article>

        <article className="flex flex-col gap-4 items-center">
          <h2>
            30% DE DESCONTO{" "}
            <mark className="bg-primary text-white">-($30)</mark> para você ter
            acesso ao{" "}
            <mark className="font-bold text-secondary bg-transparent">
              APP DO TRUQUE DA CERVEJA!
            </mark>
          </h2>

          <p>
            Sinceramente, acredito que somente um HOMEM que{" "}
            <mark className="bg-transparent font-bold text-secondary">
              {" "}
              não{" "}
            </mark>{" "}
            deseja CUIDAR do seu próprio CORPO e SAÚDE recusaria essa
            oportunidade.
          </p>

          <p className="max-w-[60ch]">
            <mark className="bg-transparent text-primary font-bold">
              CLIQUE NO BOTÃO ABAIXO
            </mark>{" "}
            agora mesmo e comece ainda hoje a fazer a{" "}
            <mark className="bg-transparent text-primary font-bold">
              RECEITINHA DA CERVEJA BARIÁTRICA
            </mark>{" "}
            que o{" "}
            <mark className="bg-transparent text-primary font-bold">
              APP DA CERVEJA
            </mark>{" "}
            vai montar para você!
          </p>

          <a
            href="https://checkout.payt.com.br/22ced79030992233449d6c1e0cea47df"
            className="button pulse-animation regular-shadow mt-4"
          >
            QUERO O APP DA CERVEJA COM 30% DE DESCONTO
          </a>
        </article>

        <article className="flex flex-col gap-4 items-center">
          <h2 className="bg-yellow-300 font-bold text-2xl md:text-4xl text-secondary w-fit">
            AVISO:
          </h2>

          <p>
            Você está coberta por nossa{" "}
            <mark className="font-bold text-secondary bg-transparent">
              GARANTIA BLINDADA…
            </mark>
          </p>

          <p>
            Se o{" "}
            <mark className="text-primary font-bold bg-transparent">
              {" "}
              APLICATIVO
            </mark>{" "}
            não gerar resultados rápidos e efetivos, ou se, por qualquer motivo,
            você não gostar do{" "}
            <mark className="text-primary font-bold bg-transparent">
              {" "}
              APP DA CERVEJA.
            </mark>{" "}
          </p>

          <p>
            Basta entrar em contato com nossa equipe por e-mail ou WhatsApp, que
            você receberá 100% do valor investido de forma rápida e sem nenhuma
            burocracia!
          </p>

          <p>
            Aproveite nosso desconto especial de AGORA POR APENAS{" "}
            <mark className="bg-green-600 text-white font-bold">R$67,00</mark>
          </p>

          <a
            href="https://checkout.payt.com.br/22ced79030992233449d6c1e0cea47df"
            className="button pulse-animation regular-shadow mt-4"
          >
            QUERO O APP DA CERVEJA COM 30% DE DESCONTO
          </a>
        </article>

        <article className="flex flex-col gap-4 items-center">
          <p>
            Teste durante 30 dias sem nenhum compromisso o{" "}
            <mark className="font-bold text-primary bg-transparent">
              APP DA CERVEJA!
            </mark>
          </p>

          <h2 className="font-bold text-secondary">
            E ainda você vai levar todos os{" "}
            <mark className="font-bold text-primary bg-transparent">BÔNUS</mark>{" "}
            e mais{" "}
            <mark className="font-bold text-primary bg-transparent">
              {" "}
              2 PRESENTES SURPRESAS
            </mark>{" "}
            que vão potencializar ainda mais os seus resultados!!!
          </h2>

          <h3 className="text-green-600 bg-transparent font-bold text-lg md:text-white md:bg-green-600">
            PARA GARANTIR ESSE MEGA DESCONTO É SÓ SEGUIR AS INSTRUÇÕES ABAIXO?
          </h3>

          <ol className="flex flex-col gap-4 text-lg">
            <li>
              <p>
                <mark className="bg-yellow-300 font-bold text-secondary">
                  Passo 1:
                </mark>{" "}
                Clique no botão abaixo e preencha seus dados nos campos
                corretos…
              </p>
            </li>
            <li>
              <p>
                <mark className="bg-yellow-300 font-bold text-secondary">
                  Passo 2:
                </mark>{" "}
                Quando concluir a compra. Você receberá o acesso pelo e-mail
                cadastrado…
              </p>
            </li>
            <li>
              <p>
                <mark className="bg-yellow-300 font-bold text-secondary">
                  Passo 3:
                </mark>{" "}
                Acesse o APLICATIVO E COMECE HOJE MESMO A FAZER{" "}
                <mark className="bg-transparent text-primary font-bold">
                  O TRUQUE DA CERVEJA!!!
                </mark>
              </p>
            </li>
          </ol>

          <a
            href="https://checkout.payt.com.br/22ced79030992233449d6c1e0cea47df"
            className="button pulse-animation regular-shadow mt-4"
          >
            QUERO O APP DA CERVEJA COM 30% DE DESCONTO
          </a>

          <h4 className="font-bold text-secondary mt-4">
            Muito obrigado pelo seu tempo!!!
          </h4>
        </article>
      </article>
    </section>
  );
}
